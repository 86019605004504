<template>
  <div
    class="container-fluid"
    style="
      user-select: none;
      background: linear-gradient(to bottom, #02133E, #02133E);
      height: auto;
      padding-top: 50px; 
      font-family: 'Roboto';
    "
  >
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="logo mt-5 d-flex">
          <img
            class="logo-img"
            src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/yy24_new-removebg-preview.png"
            style="
              width: 150px;
              height: 150px;
              margin-top: 4rem;
              margin-left: 4rem;
            "
          />
        </div>
      </div>
    </div>
    <hr>
    <div>
      <h3 class="text-white text-center">OUR TRUSTED PARTNERS</h3>
      <h4 class="text-muted text-center pt-2">ကျွန်ုပ်တို့၏ လက်တွဲဖော်အေးဂျင့်များတွင် ယုံကြည်စိတ်ချစွာကစားနိုင်ပါသည်။</h4>
        <div class="row justify-content-center mt-4">
          <div class="col-4 mx-2 my-3">
            <img src="https://cdn.gold549.com/build/assets/img/brand/green.jpg" style="width: 100%; height: 100%; background: #EEEEEE;" alt="" />
          </div>
          <div class="col-4 mx-2 my-3">
            <img src="https://cdn.gold549.com/build/assets/img/brand/agent_1.png" style="width: 100%; height: 100%; background: #EEEEEE; border-radius: 10px;" alt="" />
          </div>
          <div class="col-4 mx-2 my-3">
            <img src="https://cdn.gold549.com/build/assets/img/brand/agent_2.png" style="width: 100%; height: 100%; background: #EEEEEE; border-radius: 10px;" alt="" />
          </div>
          <div class="col-4 mx-2 my-3">
            <img src="https://cdn.gold549.com/build/assets/img/brand/agent_3.png" style="width: 100%; height: 100%; background: #EEEEEE; border-radius: 10px;" alt="" />
          </div>
        </div>  
    </div>
    <div class="row d-flex main-footer" style="margin-top: 16rem">
      <p
        class="mx-auto text-white text-sm"
        style="text-align: center; font-weight: bold"
      >
        @ GOLD549 2024 Renamed <br />
        Privacy Teams FAQ
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped>
/* .main-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
} */
.main-footer {
    padding: 10px !important;
}
    
.text-img {
  width: 150px;
  height: 150px;
}
.phone-img {
  width: 140px;
  height: 270px;
}
h5 {
  color: #fff;
}
.p1 {
  font-size: 50px;
  color: #fff;
}
.img-cont {
  width: 30rem;
}
.heading-text {
  font-size: 32px !important;
}
.para-text {
  font-size: 18px !important;
}

@media (max-width: 786px) {
  .text-img {
    width: 130px !important;
    height: 90px !important;
  }
  .phone-img {
    width: 100px !important;
    height: 180px !important;
  }
  .img-cont {
    width: 15rem;
  }
  .main-footer {
    margin-top: 0rem !important;
    padding-top: 3.5rem !important;
    /* padding-bottom: 1rem !important; */
  }
  .right-img-container {
    padding-top: 4rem !important;
  }
  .left {
    margin-left: 0.4rem !important;
  }
  .para-text {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .btn-md {
    padding: 4px 20px;
    font-size: 14px;
    margin-top: 1rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
  .logo-img {
    margin: auto !important;
    width: 120px !important;
    height: 120px !important;
  }
  .logo {
    margin-top: 1rem !important;
  }
  .heading-text {
    font-size: 25px !important;
  }
}
</style>
